import "./App.css";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AddDonation from "./pages/AddDonation";
import AddWithdrawal from "./pages/AddWithdrawal";
import HomeAdmin from "./pages/HomeAdmin";
import Login from "./pages/Login";
import AddSponsor from "./pages/AddSponsor";
import { AuthContext } from "./helpers/AuthContext";
import { useEffect, useState } from "react";
import ChangeSchoolSubject from "./pages/ChangeSchoolSubject";
import EditOrDelete from "./pages/EditOrDelete";

function App() {
  const [authState, setAuthState] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setAuthState(true);
    }
  }, []);

  return (
    <div className="App">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              {authState && (
                <>
                  <li>
                    <Link to="/admin"> Admin</Link>
                  </li>
                </>
              )}
              {!authState && (
                <>
                  <li>
                    <Link to="/login"> Login</Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/donate" element={<AddDonation />} />
            <Route path="/withdraw" element={<AddWithdrawal />} />
            <Route path="/sponsor" element={<AddSponsor />} />
            <Route path="/school-subject" element={<ChangeSchoolSubject />} />
            <Route path="/admin" element={<HomeAdmin />} />
            <Route path="/edit-or-delete" element={<EditOrDelete />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
