import React from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function AddWithdrawal() {
  const wsUrl = "https://ws.scool-corner-web-api.pl";
  //const wsUrl = "http://localhost:3001";

  const initialValues = {
    name: "",
    amount: ""
  };

  const [DonationAmounts, setDonationAmounts] = useState([]);
  const [ListOfProducts, setListOfProducts] = useState();  

  const fetchDonationAmounts = () => {
    axios.get(`${wsUrl}/donations/amounts`).then((response) => {
      setDonationAmounts(response.data);
    });
  }

  const fetchProducts = () => {
    axios.get(`${wsUrl}/products`).then((response) => {
      setListOfProducts(response.data);
    });
  }

  function checkIfBankHasEnoughMoney(productName){
    let withdrawalAmount;
    const product = ListOfProducts.find(a => a.name === productName);
    withdrawalAmount = product ? product.price : undefined;
    return withdrawalAmount <= DonationAmounts.totalRemainingAmount;
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Imię nie może być puste"),
    productName: Yup
        .string()
        .required("Wybierz produkt")
        .test("if-bank-has-enough-money", "Nie mamy tyle pieniędzy w banku", value => checkIfBankHasEnoughMoney(value))
  });

  const onSubmit = (data) => {
    const product = ListOfProducts.find(a => a.name.toString() === data.productName.toString());
    data.amount = product ? product.price - product.priceAfterDiscount : undefined;
    axios
      .post(`${wsUrl}/withdrawals`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        navigate("/");
      });
  };

  let navigate = useNavigate();
  useEffect(()=>{
    document.getElementById('body').style.backgroundColor = `rgb(215,128,23)`;
  })

  useEffect(() => {
    fetchProducts();
    fetchDonationAmounts();
  }, []);

  return (
    <div className="addWithdrawalPage">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className="formContainer">
            <div className="bankInfoWithdrawal">
              <h1>AKTUALNIE W BANKU MAMY: &nbsp;{DonationAmounts.totalRemainingAmount},-</h1>
            </div>
            <label>Imie: </label>
            <ErrorMessage name="name" component="span" />
            <Field
              className="formInput"
              name="name"
              placeholder=""
              type="text"
            />
            <label>Produkt: </label>
            <ErrorMessage name="productName" component="span" />
            <div className="radioGroup" role="group">
              <div id="productRadioButton1" className="productRadioButton">
                <Field id="radio1" type="radio" name="productName" value="icecream" />
                <label htmlFor="radio1"></label>
              </div>
              <div id="productRadioButton2" className="productRadioButton">  
                <Field id="radio2" type="radio" name="productName" value="sandwich" />
                <label htmlFor="radio2"></label>
              </div>
              <div id="productRadioButton3" className="productRadioButton">  
                <Field id="radio3" type="radio" name="productName" value="waffle" />
                <label htmlFor="radio3"></label>
              </div>  
            </div>
            <button type="submit">
              Smacznego!
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddWithdrawal;
