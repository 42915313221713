import React from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';

function HomeAdmin() {

const [authState, setAuthState] = useState(false);

useEffect(() => {
  if (localStorage.getItem("accessToken")) {
    setAuthState(true);
  }
}, []);
useEffect(()=>{
  document.getElementById('body').style.backgroundColor = `rgb(215,128,23)`;
});

  return (
    <div className="adminPanel">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        {authState && (
          <>
          <Button component={Link} to="/donate" className="adminPanelButton"> <br/> Wpłata <br></br><br/> </Button>
          
          <Button component={Link} to="/withdraw" className="adminPanelButton"> <br/>Wypłata<br></br><br/> </Button>
          
          <Button component={Link} to="/sponsor" className="adminPanelButton"> <br/>Sponsorzy<br></br><br/> </Button>
          
          <Button component={Link} to="/edit-or-delete" className="adminPanelButton"> Edycja<br/>Usuwanie </Button>
          
          <Button component={Link} to="/school-subject" className="adminPanelButton"> Bieżący<br/>przedmiot<br/>szkolny </Button>
          
          <Button component={Link} to="/" className="adminPanelButton"> Wyloguj </Button>
          </>
        )}
      </AuthContext.Provider>
    </div>
  );
}

export default HomeAdmin;
